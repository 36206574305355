import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Welcome to Toronto",
  "date": "2020-09-06"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`September already? Summer flew by too quick! Although, ever since COVID-19, time has just become a blur.`}</p>
    <p>{`The good news is that I was able to successfully move across the country and am now proud to call Toronto my new home. I moved here to be with my partner as well as desired change in scenery. So far, I am loving the vibrant culture of the big city.`}</p>
    <p>{`I am thankful for the last 6 years in Vancouver where I made some lifelong friends, revelled in the great outdoors, and matured a lot. It was there that I realized my passion for tech and building products, which motivated the decision to leave my corporate job to study at `}<a href="https://lighthouselabs.ca" target="_blank">{`Lighthouse Labs`}</a>{` to become a developer. It has been an amazing journey since then where I've had the fortune of working as a professional software developer for 3 years and navigated my my way into product management.`}</p>
    <p>{`Next up on my list is to build my network, establish some healthy routines, and explore the tech scene.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      